<template>
  <div class="customize-tab">
    <section class="section">
      <div class="container">
        <h1 class="title is-marginless">
          Customize Branding & Color
        </h1>
        <h1 class="subtitle is-marginless">
          Applied to your games and instructor page
        </h1>
        <div class="section mt-2">
          <b-notification
            :active="showError"
            @close="closeError"
            type="is-danger"
            >{{ showError ? error.message : "" }}</b-notification
          >
          <b-message type="is-info" v-if="!teamAccount">
            Please subscribe to change the branding of your games.
          </b-message>
          <div class="profile-info-bloc box" style="position: relative;">
            <h2 class="title is-5 mb-3">
              Logo
            </h2>
            <b-field class="section">
              <b-field class="file">
                <b-upload
                  v-model="logoFile"
                  drag-drop
                  @input="newFile"
                  :disabled="!teamAccount"
                >
                  <section class="section">
                    <div class="has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-medium"> </b-icon>
                      </p>
                      <p>
                        Drop file or Click to upload
                        <br />
                        <em>.PNG / .JPG &lt; 2MB</em>
                      </p>
                      <!-- <p></p> -->
                    </div>
                  </section>
                </b-upload>
                <div class="section">
                  <image-mini
                    v-if="
                      !logoFile && (!publicProfile || !publicProfile.logoUrl)
                    "
                    :bigSrc="require('@/../public/favicon-96.png')"
                    :smallSrc="require('@/../public/favicon-96.png')"
                    :shortCaption="'Current logo'"
                    :longCaption="'Current logo'"
                    :bckColor="newColors.bckColor"
                  ></image-mini>
                  <image-mini
                    v-if="!logoFile && publicProfile && publicProfile.logoUrl"
                    :bigSrc="publicProfile.logoUrl"
                    :smallSrc="publicProfile.logoUrl"
                    :shortCaption="'Current logo'"
                    :longCaption="'Current logo'"
                    :bckColor="newColors.bckColor"
                  ></image-mini>
                  <em v-if="logoFile">Loading file {{ logoFile.name }}.. </em>
                </div>
              </b-field>
            </b-field>
          </div>
          <div class="profile-info-bloc box">
            <h2 class="title is-5 mb-3">
              Colors
            </h2>
            <div class="columns section is-multiline">
              <b-field class="column is-half">
                <template slot="label">
                  <span class="is-size-5">
                    Main color :
                  </span>
                </template>
                <b-field grouped>
                  <v-swatches
                    class="mr-3"
                    v-model="newColors.mainColor"
                    swatches="text-advanced"
                    :disabled="!teamAccount || !modifyColors"
                    show-fallback
                    fallback-input-type="color"
                  ></v-swatches>
                  <b-field
                    expanded
                    :type="{ 'is-danger': $v.newColors.mainColor.$error }"
                    :message="
                      $v.newColors.mainColor.$error
                        ? 'Invalid HEX Color format'
                        : ''
                    "
                  >
                    <b-input
                      v-model="$v.newColors.mainColor.$model"
                      type="text"
                      @keyup.enter.native="updateCustoLook"
                      :disabled="!modifyColors"
                    ></b-input>
                  </b-field>
                </b-field>
              </b-field>
              <b-field class="column is-half">
                <template slot="label">
                  <span class="is-size-5">
                    Secondary Color :
                  </span>
                </template>
                <b-field grouped>
                  <v-swatches
                    class="mr-3"
                    v-model="newColors.bckColor"
                    swatches="text-advanced"
                    :disabled="!teamAccount || !modifyColors"
                    show-fallback
                    fallback-input-type="color"
                  ></v-swatches>
                  <b-field
                    expanded
                    :type="{ 'is-danger': $v.newColors.bckColor.$error }"
                    :message="
                      $v.newColors.bckColor.$error
                        ? 'Invalid HEX Color format'
                        : ''
                    "
                  >
                    <b-input
                      v-model="$v.newColors.bckColor.$model"
                      type="text"
                      @keyup.enter.native="updateCustoLook"
                      :disabled="!modifyColors"
                    ></b-input>
                  </b-field>
                </b-field>
              </b-field>
              <div
                class="column is-one-quarter has-text-centered"
                v-if="modifyColors"
              >
                <div
                  class="title is-6 rendering-div"
                  :style="{
                    'background-color': newColors.bckColor,
                    color: newColors.mainColor
                  }"
                >
                  Result
                </div>
              </div>
              <div
                class="column is-three-quarters is-italic"
                v-if="modifyColors"
              >
                Choose two colors from your corporate design identity that have
                good contrast between them.
              </div>

              <div class="column is-full has-text-right">
                <b-button
                  type="is-warning"
                  @click="modifyColors = true"
                  :disabled="!teamAccount"
                  v-if="!modifyColors"
                  >Update</b-button
                >
                <b-button
                  type="is-danger"
                  class="mr10"
                  @click="resetColors"
                  v-if="modifyColors"
                  >Cancel</b-button
                >
                <b-button
                  type="is-success"
                  @click="updateCustoLook"
                  v-if="modifyColors"
                  :disabled="$v.newColors.$invalid"
                  >OK</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import ImageMini from "@/components/ImageMini.vue";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
const hexFormat = value => {
  return /^#([0-9A-F]{3}){1,2}$/i.test(value);
};

export default {
  name: "brandingTab",
  components: { ImageMini, VSwatches },
  computed: {
    ...mapGetters([
      "publicProfile",
      "custoLook",
      "authUser",
      "teamAccount",
      "error",
      "showError"
    ])
  },
  created() {
    this.resetColors();
  },
  methods: {
    closeError() {
      this.$store.commit("clearError");
    },
    resetColors() {
      this.modifyColors = false;
      if (this.publicProfile && this.publicProfile.mainColor) {
        this.newColors.mainColor = this.publicProfile.mainColor;
      }
      if (this.publicProfile && this.publicProfile.bckColor) {
        this.newColors.bckColor = this.publicProfile.bckColor;
      }
    },
    newFile(file) {
      this.$store
        .dispatch("saveLogo", {
          uid: this.authUser.uid,
          file: file
        })
        .then(() => {
          this.logoFile = null;
        });
    },
    updateCustoLook() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.modifyColors = false;
        this.$store.dispatch("updatePublicProfile", {
          uid: this.authUser.uid,
          obj: this.newColors
        });
      }
    }
  },
  validations: {
    newColors: {
      bckColor: {
        required,
        hexFormat
      },
      mainColor: {
        required,
        hexFormat
      }
    }
  },
  data() {
    return {
      modifyColors: false,
      logoFile: null,
      newColors: {
        bckColor: "#FFF",
        mainColor: "#FF8C89"
      }
    };
  }
};
</script>
<style lang="scss">
.customize-tab {
  .profile-info-bloc {
    .new-color-swatch {
      display: inline-block;
      border: 1px solid black;
      margin-right: 1em;
      background-color: green;
      border-radius: 3px;
      width: 2.3em;
      height: 2.3em;
    }
    .vue-swatches__trigger {
      border: lightgrey 1px solid;
    }
    .upload-draggable {
      display: flex;
      align-items: center;
      justify-content: center;
      .section {
        padding: 1em;
      }
    }
    .rendering-div {
      display: inline-block;
      padding: 1em;
      border: 1px solid lightgrey;
    }
    .section .image-mini-wrapper {
      margin-left: 2em;

      img {
        max-height: 100px;
        padding: 0.5em;
      }
    }
    .mr10 {
      margin-right: 1em;
    }
    .file {
      .section {
        padding: 1rem;
      }
    }
    .field-body {
      flex-grow: 3 !important;
    }
    .checkbox-field {
      .field-body {
        padding-top: 0.4em;
      }
    }
  }
}
</style>
