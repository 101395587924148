var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"customize-tab"},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"title is-marginless"},[_vm._v(" Customize Branding & Color ")]),_c('h1',{staticClass:"subtitle is-marginless"},[_vm._v(" Applied to your games and instructor page ")]),_c('div',{staticClass:"section mt-2"},[_c('b-notification',{attrs:{"active":_vm.showError,"type":"is-danger"},on:{"close":_vm.closeError}},[_vm._v(_vm._s(_vm.showError ? _vm.error.message : ""))]),(!_vm.teamAccount)?_c('b-message',{attrs:{"type":"is-info"}},[_vm._v(" Please subscribe to change the branding of your games. ")]):_vm._e(),_c('div',{staticClass:"profile-info-bloc box",staticStyle:{"position":"relative"}},[_c('h2',{staticClass:"title is-5 mb-3"},[_vm._v(" Logo ")]),_c('b-field',{staticClass:"section"},[_c('b-field',{staticClass:"file"},[_c('b-upload',{attrs:{"drag-drop":"","disabled":!_vm.teamAccount},on:{"input":_vm.newFile},model:{value:(_vm.logoFile),callback:function ($$v) {_vm.logoFile=$$v},expression:"logoFile"}},[_c('section',{staticClass:"section"},[_c('div',{staticClass:"has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-medium"}})],1),_c('p',[_vm._v(" Drop file or Click to upload "),_c('br'),_c('em',[_vm._v(".PNG / .JPG < 2MB")])])])])]),_c('div',{staticClass:"section"},[(
                    !_vm.logoFile && (!_vm.publicProfile || !_vm.publicProfile.logoUrl)
                  )?_c('image-mini',{attrs:{"bigSrc":require('@/../public/favicon-96.png'),"smallSrc":require('@/../public/favicon-96.png'),"shortCaption":'Current logo',"longCaption":'Current logo',"bckColor":_vm.newColors.bckColor}}):_vm._e(),(!_vm.logoFile && _vm.publicProfile && _vm.publicProfile.logoUrl)?_c('image-mini',{attrs:{"bigSrc":_vm.publicProfile.logoUrl,"smallSrc":_vm.publicProfile.logoUrl,"shortCaption":'Current logo',"longCaption":'Current logo',"bckColor":_vm.newColors.bckColor}}):_vm._e(),(_vm.logoFile)?_c('em',[_vm._v("Loading file "+_vm._s(_vm.logoFile.name)+".. ")]):_vm._e()],1)],1)],1)],1),_c('div',{staticClass:"profile-info-bloc box"},[_c('h2',{staticClass:"title is-5 mb-3"},[_vm._v(" Colors ")]),_c('div',{staticClass:"columns section is-multiline"},[_c('b-field',{staticClass:"column is-half"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-5"},[_vm._v(" Main color : ")])]),_c('b-field',{attrs:{"grouped":""}},[_c('v-swatches',{staticClass:"mr-3",attrs:{"swatches":"text-advanced","disabled":!_vm.teamAccount || !_vm.modifyColors,"show-fallback":"","fallback-input-type":"color"},model:{value:(_vm.newColors.mainColor),callback:function ($$v) {_vm.$set(_vm.newColors, "mainColor", $$v)},expression:"newColors.mainColor"}}),_c('b-field',{attrs:{"expanded":"","type":{ 'is-danger': _vm.$v.newColors.mainColor.$error },"message":_vm.$v.newColors.mainColor.$error
                      ? 'Invalid HEX Color format'
                      : ''}},[_c('b-input',{attrs:{"type":"text","disabled":!_vm.modifyColors},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateCustoLook($event)}},model:{value:(_vm.$v.newColors.mainColor.$model),callback:function ($$v) {_vm.$set(_vm.$v.newColors.mainColor, "$model", $$v)},expression:"$v.newColors.mainColor.$model"}})],1)],1)],2),_c('b-field',{staticClass:"column is-half"},[_c('template',{slot:"label"},[_c('span',{staticClass:"is-size-5"},[_vm._v(" Secondary Color : ")])]),_c('b-field',{attrs:{"grouped":""}},[_c('v-swatches',{staticClass:"mr-3",attrs:{"swatches":"text-advanced","disabled":!_vm.teamAccount || !_vm.modifyColors,"show-fallback":"","fallback-input-type":"color"},model:{value:(_vm.newColors.bckColor),callback:function ($$v) {_vm.$set(_vm.newColors, "bckColor", $$v)},expression:"newColors.bckColor"}}),_c('b-field',{attrs:{"expanded":"","type":{ 'is-danger': _vm.$v.newColors.bckColor.$error },"message":_vm.$v.newColors.bckColor.$error
                      ? 'Invalid HEX Color format'
                      : ''}},[_c('b-input',{attrs:{"type":"text","disabled":!_vm.modifyColors},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateCustoLook($event)}},model:{value:(_vm.$v.newColors.bckColor.$model),callback:function ($$v) {_vm.$set(_vm.$v.newColors.bckColor, "$model", $$v)},expression:"$v.newColors.bckColor.$model"}})],1)],1)],2),(_vm.modifyColors)?_c('div',{staticClass:"column is-one-quarter has-text-centered"},[_c('div',{staticClass:"title is-6 rendering-div",style:({
                  'background-color': _vm.newColors.bckColor,
                  color: _vm.newColors.mainColor
                })},[_vm._v(" Result ")])]):_vm._e(),(_vm.modifyColors)?_c('div',{staticClass:"column is-three-quarters is-italic"},[_vm._v(" Choose two colors from your corporate design identity that have good contrast between them. ")]):_vm._e(),_c('div',{staticClass:"column is-full has-text-right"},[(!_vm.modifyColors)?_c('b-button',{attrs:{"type":"is-warning","disabled":!_vm.teamAccount},on:{"click":function($event){_vm.modifyColors = true}}},[_vm._v("Update")]):_vm._e(),(_vm.modifyColors)?_c('b-button',{staticClass:"mr10",attrs:{"type":"is-danger"},on:{"click":_vm.resetColors}},[_vm._v("Cancel")]):_vm._e(),(_vm.modifyColors)?_c('b-button',{attrs:{"type":"is-success","disabled":_vm.$v.newColors.$invalid},on:{"click":_vm.updateCustoLook}},[_vm._v("OK")]):_vm._e()],1)],1)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }