<template>
  <div class="image-mini-wrapper has-text-centered">
    <a @click="clickImg">
      <div
        class="mini-img-div"
        :class="{ backcol: bckColor }"
        :style="{ 'background-color': bckColor || 'none' }"
      >
        <img class="mini-img" :src="smallSrc" />
      </div>
    </a>
    <span>{{ shortCaption }}</span>
    <b-modal
      trap-focus
      :active.sync="isImageModalActive"
      v-if="selectImgSrc"
      class="has-text-centered"
    >
      <div
        class="image has-text-centered"
        :style="{ 'background-color': bckColor || 'none' }"
      >
        <img :src="selectImgSrc" />
      </div>
      <p class="has-text-white has-text-centered is-italic is-size-5">
        {{ longCaption }}
      </p>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "image-mini",
  props: {
    bckColor: String,
    bigSrc: String,
    smallSrc: String,
    shortCaption: String,
    longCaption: String
  },
  methods: {
    clickImg() {
      this.isImageModalActive = true;
      this.selectImgSrc = this.bigSrc;
    }
  },
  data() {
    return {
      isImageModalActive: false,
      selectImgSrc: null
    };
  }
};
</script>

<style lang="scss" scoped>
.image-mini-wrapper {
  .mini-img {
    max-height: 150px;
    border: solid lightgrey 1px;
  }
  .backcol .mini-img {
    border: none;
  }
  .modal .modal-content {
    width: auto;
    .image {
      display: inline-block;
      width: auto;
      img {
        width: auto;
        max-height: calc(100vh - 200px);
        max-width: 100%;
      }
    }
    .has-text-white {
      margin-top: 0.5em;
    }
  }
}
</style>
